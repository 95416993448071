<template>
  <div class="iframe-container" style="font-size: 0;">
    <Spin size="large" fix v-if="loading">
      <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
      <div>加载资源中，请稍后...</div>
    </Spin>
    <!-- <iframe
      v-for="(src, index) in srcs"
      v-show="index === curIndex"
      :key="src"
      :src="src"
      frameborder="0"
      class="iframe"
      ref="iframe"
    /> -->
    <iframe :src="externalLink" frameborder="0" class="iframe" scrolling="auto" ref="iframe" />
  </div>
</template>

<script>
const { mapGetters } = window.Vuex;
import { CLIENT_ID } from "@/app.js";
const iframeLoadDone = (iframe, cb) => {
  if (iframe.addEventListener) {
    iframe.addEventListener("load", function() {
      cb && cb();
    });
  } else if (iframe.attachEvent) {
    iframe.attachEvent("onload", function() {
      cb && cb();
    });
  } else {
    var isIE = /msie/i.test(navigator.userAgent) && !window.opera;
    if (isIE) {
      iframe.onreadystatechange = function() {
        if (iframe.readyState == "loaded" || iframe.readyState == "complete") {
          cb && cb();
        }
      };
    } else {
      iframe.onload = function() {
        cb && cb();
      };
    }
  }
};
export default {
  name: "external",
  data() {
    return {
      srcs: [],
      curIndex: 0,
      query: "",
      loading: true,
      externalLink: ""
    };
  },
  computed: {
    ...mapGetters(["session", "tenantId"])
  },
  methods: {
    addQuery(src) {
      if (/[\W\w]+\?([\W\w]+)$/.test(src)) {
        return `${src}&${this.query}`;
      } else {
        return `${src}?${this.query}`;
      }
    },
    loadingIframe(curIframe) {
      const self = this;
      this.loading = true;
      this.$nextTick(() => {
        let timer = setTimeout(() => {
          self.loading = false;
          clearTimeout(timer);
          timer = null;
        }, 10000);
        if (curIframe) {
          iframeLoadDone(curIframe, () => {
            self.loading = false;
            clearTimeout(timer);
            timer = null;
          });
        }
      });
    },
    addSrc(route) {
      if (route.path.indexOf("external") > -1) {
        let src = route.query.src,
          index = -1;
        if (this.srcs.length) {
          index = this.srcs.findIndex(item => {
            return item.startsWith(src + '?');
          });
        }
        if (index === -1) {
          src = this.addQuery(src);
          this.srcs.push(src);
          this.curIndex = this.srcs.length - 1;
          let self = this;
          this.$nextTick(() => {
            if (this.$refs.iframe.length === this.srcs.length) {
              let curIframe = this.$refs.iframe[this.curIndex];
              this.loadingIframe(curIframe);
            }
          });
        } else {
          this.curIndex = index;
        }
      }
    },
    addExternalLink(route) {
      let src = route.query.src;
      if (src) {
        this.externalLink = this.addQuery(src);
        this.loadingIframe(this.$refs.iframe);
      } else {
        this.loading = false;
        this.externalLink = "";
      }
    }
  },
  watch: {
    $route(to) {
      this.addExternalLink(to);
      // this.addSrc(to);
    }
  },
  mounted() {
    this.query = `access_token=${this.session.token}&is_portal=1&&client_id=${CLIENT_ID}`;
    this.addExternalLink(this.$route);
    // this.addSrc(this.$route);
  }
};
</script>

<style lang="less">
.iframe {
  border-style: inset;
  border-color: initial;
  border-image: initial;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.iframe-container {
  position: relative;
  height: 100%;
  background-color: ;
}
.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.demo-spin-col {
  height: 100px;
  position: relative;
  border: 1px solid #eee;
}
</style>